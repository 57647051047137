<template>
  <el-col>other</el-col>
</template>

<script>
export default {
  name: "other"
}
</script>

<style scoped>

</style>