<template>
  <el-col>
    <el-row style="margin-bottom: 10px">
      <el-card class="box-card">
      <el-tag type="success" style="margin-left: 80px;margin-bottom: 10px">{{userName}}</el-tag>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="原始密码">
          <el-input v-model="form.srcPwd" prefix-icon="el-icon-s-custom" clearable show-password minlength=6 maxlength=16 style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="新的密码">
          <el-input v-model="form.newPwd" prefix-icon="el-icon-s-check" clearable show-password minlength=6 maxlength=16 style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input v-model="form.newPwd2" prefix-icon="el-icon-s-check" clearable show-password minlength=6 maxlength=16 style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="changePwd">确 定</el-button>
        </el-form-item>
      </el-form>
      </el-card>
    </el-row>
  </el-col>
</template>

<script>
export default {
  data() {
    return{
      userName : window.sessionStorage.getItem('account'),
      form:{
        srcPwd:'',
        newPwd:'',
        newPwd2:''
      }
    }
  },
  methods:{
    async changePwd(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }
      console.log(this.form);
      if (this.form.newPwd.length < 5 || this.form.newPwd.length >15 || this.form.newPwd2.length < 5 || this.form.newPwd2.length >15){
        this.$msgNot.error('新密码长度最小6位,最大16位');
        return;
      }
      if (this.form.newPwd !== this.form.newPwd2){
        this.$msgNot.error('新密码与确认密码不一致');
        return;
      }
      const {data:res} = await this.$ajax.post("/api/admin/changePwd",{'account':this.userName,'data':this.form});
      if (res.code === 200){
        this.$msgNot.success('修改密码:'+res.msg);
        this.form.srcPwd = '';
        this.form.newPwd = '';
        this.form.newPwd2= '';
      }else {
        this.$msgNot.error('修改密码:'+res.msg);
      }

    },
  }
}
</script>

<style scoped>

</style>