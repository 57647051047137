<template>
  <el-row style="margin-top: -10px;border-radius: 4px">
    <el-col :span="8">
      <el-card class="box-card">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="更新内容">
            <el-input type="textarea" :rows="5" v-model="form.msg"></el-input>
          </el-form-item>
          <el-form-item label="版本号">
            <el-input v-model="form.version"></el-input>
          </el-form-item>
          <el-form-item label="下载超时">
            <el-input v-model="form.download_timeout"></el-input>
          </el-form-item>
          <el-form-item >
            <span>
              <el-switch v-model="form.dialog" active-text="弹出对话框确认" style="margin-right: 50px"></el-switch>
              <el-switch v-model="form.force" active-text="强制更新"></el-switch>
            </span>
          </el-form-item>
          <el-form-item label="更新文件">
            <el-upload
                class="upload-demo"
                ref="upload"
                drag
                action="#"
                list-type="iec"
                accept="iec"
                :multiple="false"
                :on-change="handleChange"
                :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>只支持后缀文件为iec</div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="postForm">确 定</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
    <el-col :span="12">
      <el-card class="box-card">

      </el-card>
    </el-col>
  </el-row>

</template>

<script>
export default {

  data(){
    return {
      userName : window.sessionStorage.getItem('account'),
      form: {
        msg:'新版本更新',download_timeout:60, dialog:true,version:'100',force:false
      },

      file:null

    }

  },
  created() {
    this.loadConfig();
  },
  methods :{
    async postForm(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      this.$msgBox.confirm('确认操作？','提示',{confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',})
          .then(async ()=>{
            if (!this.form.version || !this.file){
              this.$msgNot.error('文件/版本号,不能为空');
              return;
            }

            const loading = this.$loading({
              lock: true,
              text: '正在上传文件...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });

            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('account', this.userName);
            formData.append('form',JSON.stringify(this.form));

            // formData.append('msg', this.form.msg);
            // formData.append('version', this.form.version);
            // formData.append('dialog', this.form.dialog);
            // formData.append('force', this.form.force);
            // formData.append('download_timeout', this.form.download_timeout);

            console.log(formData);
            await this.$ajax.post('/api/admin/setUpScript', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                  console.log(response.data);
                  if (response.data.code === 200){
                    this.$msgNot.success(response.data.msg+': '+this.file.name);
                  }else {
                    this.$msgNot.error('失败1:' + response.data.msg);
                  }
            }).catch(error => {
              this.$msgNot.error('失败2:'+ error);
            });

            loading.close();

          }).catch(()=>{
            this.$msgNot.error('操作异常');
          });

    },

    async loadConfig(){
      await this.$ajax.get('/api/other/UpScript')
          .then(response => {
            console.log(response.data);
            if (response.data){
              this.form = response.data;
            }else {
              this.$msgNot.error('读取默认失败:');
            }
          }).catch(error => {
            this.$msgNot.error('读取默认失败2:'+ error);
          });
    },
    handleChange(file) {
      this.file = file.raw;
    },

  },
}
</script>

<style scoped>
  .box-card {
    width: 480px;
  }
</style>