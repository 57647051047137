<template>

  <el-container class="el-containerMain">

  <div>

<!--    <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">-->
<!--      <el-radio-button :label="false">>></el-radio-button>-->
<!--      <el-radio-button :label="true"><<</el-radio-button>-->
<!--    </el-radio-group>-->
      <el-menu
          default-active="config"
          class="el-aside"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          :collapse="isCollapse"
          router
      >
        <el-menu-item index="/config">
          <i class="el-icon-mobile-phone"></i>
          <span slot="title">设备管理</span>
        </el-menu-item>
        <el-menu-item index="/dmgt">
          <i class="el-icon-s-grid"></i>
          <span slot="title">数据管理</span>
        </el-menu-item>
        <el-menu-item index="/myset">
          <i class="el-icon-setting"></i>
          <span slot="title">我的资料</span>
        </el-menu-item>
        <el-menu-item index="/other">
          <i class="el-icon-s-data"></i>
          <span slot="title">其他设置</span>
        </el-menu-item>
        <div v-if="role===8">
          <el-menu-item index="/admin">
            <i class="el-icon-box"></i>
            <span slot="title">管理设置</span>
          </el-menu-item>
          <el-menu-item index="/keylist">
            <i class="el-icon-bank-card"></i>
            <span slot="title">卡密设置</span>
          </el-menu-item>
          <el-menu-item index="/userList">
            <i class="el-icon-bank-card"></i>
            <span slot="title">用户列表</span>
          </el-menu-item>
        </div>
        <el-menu-item index="/logout">
          <i class="el-icon-switch-button"></i>
          <span slot="title">退出登录</span>
        </el-menu-item>
      </el-menu>

  </div>
    <el-container>
      <el-header style="height: 30px;">
        <!--        <el-col><el-button type="info" size="small" icon="el-icon-switch-button">退出</el-button></el-col>-->
      </el-header>
      <el-main >
        <router-view></router-view>
      </el-main>
            <el-footer height="30px"></el-footer>
    </el-container>


  </el-container>
</template>

<script>
export default {
  name: "home",

  data() {
    return {
      isCollapse: false,
      role:0
    };
  },
  created() {
    this.chkAdmin();
    this.$router.push('/config');
  },
  computed: {

  },
  methods: {
    async chkAdmin() {
      const {data:res} = await this.$ajax.post("/api/admin/chkrole",{account: window.sessionStorage.getItem('account')});
      if (res.code === 200){
        this.role = res.data;
      }
    }
  }
}
</script>

<style>

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 500px;
}
.el-containerMain{
  /*height: 1000px;*/
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.el-header, .el-footer {
  background-color: #cfcfcf;
  color: #121212;
  text-align: left;
  line-height: 30px;
  height: 30px;
  /*border-radius: 30px;*/
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)*/
}

.el-aside {
  background-color: #222D32;
  color: #ffffff;
  text-align: left;
  line-height: 50px;
  width: 130px;
  height: 100%;
}

.el-main {
  background-color: #ffffff;
  color: #333;
  text-align: left;
  line-height: 30px;
  height: 100%;

}

body > .el-container {
  margin-bottom: 30px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>