import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import axios from 'axios'
import store from './store'
import VueClipboard from 'vue-clipboard2';

axios.interceptors.request.use(config=>{
  
  config.headers.token = window.sessionStorage.getItem('token');
  // console.log('config:'+JSON.stringify(config));
  return config;
})



Vue.prototype.$ajax = axios;

Vue.config.productionTip = false
Vue.use(VueClipboard);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
