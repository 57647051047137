<template>
  <el-row style="margin-bottom: 10px;margin-top: -15px">
    <el-card class="box-card" style="margin-bottom: 5px">
      <el-form :rules="rules" ref="addForm" :model="addForm" label-width="120px" style="margin-top: -10px;margin-bottom: -10px">
        <span >
          <el-form-item prop="addCount" label="增加设备数量" style="margin: 2px" :rules="[
            { required: true, message: '设备数量不能为空'},
            { type: 'number', message: '只能输入数字'}
          ]">
            <el-input v-model.number="addForm.addCount" autocomplete="off" style="width: 80px;margin-right: 10px"></el-input>
            <el-button type="primary" @click="postForm('addForm')">增加设备</el-button>
            <el-button type="success" @click="refreshData">刷新数据</el-button>
          </el-form-item>
        </span>

      </el-form>
    </el-card>
    <el-col style="margin-top: -5px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pagParams.page"
          :page-sizes="[20, 100, 200, 500]"
          :page-size="this.pagParams.limit"
          :total="this.pagParams.total"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </el-col>
    <el-table
        class="el-ta"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        stripe
        border
        size="mini"
        highlight-current-row
        highlight-selection-row
    >

      <el-table-column
          type="selection"
          width="40px">
      </el-table-column>

<!--      <el-table-column-->
<!--          prop="sort"-->
<!--          label="序号"-->
<!--          width="80px"-->
<!--          sortable-->
<!--          show-overflow-tooltip>-->
<!--        <template slot-scope="scope">{{ scope.$index+1 }}</template>-->
<!--      </el-table-column>-->
      <el-table-column
          prop="machine"
          label="机器编号"
          width="100px"
          sortable
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="serial"
          label="设备序列号"
          width="150px"
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="state"
          label="状态"
          width="100px"
          sortable
          :filters="[{ text: '未激活', value: 0 }, { text: '已激活', value: 1 }]"
          :filter-method="filterStatus"
          filter-placement="bottom-end"
      >
        <template slot-scope="scope">
          <el-tag
              :type="scope.row.state === 0 ? 'danger' : 'success'"
              disable-transitions>{{scope.row.state === 1 ? '已激活' : '未激活'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="cmd"
          label="正在任务"
          width="300px"
          sortable
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="heartime"
          label="最后连接"
          width="100px"
          sortable
          show-overflow-tooltip
          :formatter="formatDate"
      >
        <template slot-scope="scope">
          {{scope.row.heartime ? timeAgo(scope.row.heartime*1000) : '' }}
        </template>
      </el-table-column>
      <el-table-column
          prop="exp_time"
          label="剩余时间"
          width="100px"
          sortable
          show-overflow-tooltip
          :formatter="formatDate"
      >
        <template slot-scope="scope">
          {{scope.row.exp_time ? calculateDays(scope.row.exp_time): ''}}
        </template>
      </el-table-column>
      <el-table-column
          prop="ip"
          label="IP地址"
          width="120px"
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="work"
          label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-s-flag" size="mini" @click="showAcDiablo(scope.row)">激活</el-button>
          <el-button icon="el-icon-edit" size="mini" @click="showAcDiablo2(scope.row)">更改</el-button>
          <el-button icon="el-icon-document" size="mini" @click="showAcDiablo3(scope.row)">日志</el-button>
<!--          <el-button type="warning" icon="el-icon-error" size="mini" @click="delData(scope.row)">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
        :visible.sync="czVisible"
        width="30%"
        title="激活设备"
    >
      <el-form ref="form" :model="acForm" label-width="80px">
        <el-form-item label="设备编号">
          <el-input v-model="acForm.machine" disabled style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="充值卡密">
          <el-input v-model="acForm.key" clearable style="width: 80%"></el-input>
        </el-form-item>

        <el-form-item>
          <!--          <el-button @click="dialogVisible = false">取 消</el-button>-->
          <el-button type="primary" @click="acDevice()">确 定</el-button>

        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
        :visible.sync="czVisible2"
        width="30%"
        title="更改编号"
    >
      <el-form ref="form2" :model="acForm2" label-width="80px">
        <el-form-item label="设备编号">
          <el-input v-model="acForm2.machine" style="width: 80%"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="upMachine()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
        :visible.sync="czVisible3"
        width="30%"
        title="脚本运行日志"
    >
      <el-input type="textarea" :rows="30" v-model="machineLog" style="width: 90%;margin-top: -30px"></el-input>
    </el-dialog>
  </el-row>
</template>

<script>

import { Base64 } from "js-base64";
import { timeago } from '@/utils/formatTime'

export default {
  data(){
    return {
      userName : window.sessionStorage.getItem('account'),
      tableData: [],
      addForm: {
        addCount:10
      },
      pagParams:{
        total:20,limit:20,page:1,searchval:''
      },
      acForm:{
        key:'',
        machine:''
      },
      acForm2:{
        machine_src:'',
        machine:''
      },
      rules: {
        name: [
          { type: 'Number',required: true, message: '请输入生成数量', trigger: 'blur'},
          {min: 1, max: 3, message: '长度在 1 到 3 个字符', trigger: 'blur'}
        ],
      },
      machineLog:'',
      czVisible:false,
      czVisible2:false,
      czVisible3:false,
    }
  },
  created() {
    this.getList();
  },
  methods:{
    filterStatus(value, row) {
      return row.state === value;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pagParams.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pagParams.page = val;
      this.getList();
    },
    refreshData(){
      this.pagParams.page  = 1;
      // this.pagParams.limit = 5;
      // this.pagParams.total = 5;
      this.pagParams.searchval = '';
      this.getList();
    },
    showAcDiablo(row){
      this.czVisible = !this.czVisible;
      if (this.czVisible){
        this.acForm.key = '';
        this.acForm.machine = row.machine;
      }

    },
    showAcDiablo2(row){
      this.czVisible2 = !this.czVisible2;
      if (this.czVisible2){
        this.acForm2.machine = row.machine;
        this.acForm2.machine_src = row.machine;
      }

    },
    showAcDiablo3(row){
      this.czVisible3 = !this.czVisible3;
      if (this.czVisible3){
        this.machineLog = Base64.decode(row.log);
      }

    },
    async acDevice(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      const {data:res} = await this.$ajax.post("/api/keylist/acDevice",{'account':this.userName,'data':this.acForm});
      if (res.code === 200){
        this.czVisible = false;
        this.$msgNot.success(res.msg);
        await this.getList();
      }else {
        this.$msgNot.error(res.msg);
      }

    },
    async upMachine(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      const {data:res} = await this.$ajax.post("/api/device/upMachine",{'account':this.userName,'data':this.acForm2});
      if (res.code === 200){
        this.czVisible2 = false;
        this.$msgNot.success(res.msg);
        await this.getList();
      }else {
        this.$msgNot.error(res.msg);
      }

    },
    async getList(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      const {data:res} = await this.$ajax.post("/api/device/getList",{'account':this.userName,'data':this.pagParams});
      if (res.code === 200){
        this.tableData = res.data;
        this.pagParams.total = res.total;

      }else {
        this.$msgNot.error('失败:'+res.msg);
      }

    },
    async postForm(formName) {
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }
      // console.log(this.$refs[formName])

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await this.$msgBox.confirm('确认添加设备？','提示',{confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',})
              .then(async ()=>{
                const {data: res} = await this.$ajax.post("/api/device/addDevice", {'account': this.userName, 'data':this.addForm});
                if (res.code === 200) {
                  this.$msgNot.success(res.msg);
                  await this.getList();
                } else {
                  this.$msgNot.error(res.msg);
                }

              }).catch(()=>{

              });



        } else {
          console.log('error postForm!!');
          return false;
        }
      });

    },
    formatDate(row, column) {
      if(!row[column.property]) return '';

      let date = new Date(row[column.property] * 1000)
      const Y = date.getFullYear() + '-'
      const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
      const D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
      const h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
      const m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':'
      const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return Y + M + D + h + m + s
    },
    calculateDays(caTime) {
      let t = (caTime - (Date.now() /1000)) / 86400;
      let s = Math.floor(t+1)+'天';
      if (t < 0) s ="过期"
      return s;
    },
    timeAgo(val) {
      return timeago(val)
    },

  },
}
</script>

<style scoped>
.el-ta {
  /*background-color: #222D32;*/
  color: #000000;
  text-align: left;
  /*line-height: 150px;*/
  margin: 1px;
  padding: 1px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>