<template>
  <el-col >
    <el-form ref="form" :model="loginData"  label-width="80px" >
      <el-form-item label="账号">
        <el-input v-model="loginData.account" clearable autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="loginData.pwd" clearable show-password autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onLogin">登录</el-button>
      </el-form-item>
  </el-form>
  </el-col>
</template>

<script>
export default {
  data() {
    return {
      loginData: {
        account: '',
        pwd: '',
      }
    }
  },
  created() {

  },
  mounted() {

  },

  methods:{
    async onLogin() {
      const {data:res} = await this.$ajax.post("/api/admin/login",this.loginData);
      if (res.code === 200){
        this.$msg.success('登录成功');
        window.sessionStorage.setItem('account',this.loginData.account);
        window.sessionStorage.setItem('token',res.data.token);
        window.sessionStorage.setItem('expTime',res.data.expTime);
        window.sessionStorage.setItem('loginTime',new Date().getTime().toString());

        await this.$router.push('/');

      }else this.$msg.error('登录错误:'+res.msg);
    }
  }
}
</script>

<style scoped>

</style>